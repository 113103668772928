<template>
  <footer class="footer view_width-full">
    <a-row :gutter="40" type="flex" class="footer_row">
      <a-col
        :xs="12"
        :sm="12"
        :md="8"
        :lg="4"
        :xl="4"
        :xxl="4"
        v-for="(item, index) in footerList"
        :key="index"
        class="space_b_fifteen"
      >
        <h2
          class="row__title mouse-style hover-move"
          @click="toPage(item.path_name, item)"
        >
          {{ item.title }}
        </h2>
        <a-row>
          <a-row v-for="(item1, index1) in item.child" :key="index1">
            <h3
              class="row_col__item mouse-style hover-move font-size_16"
              @click="toPage(item1.path_name, item1)"
            >
              {{ item1.title }}
            </h3>
          </a-row>
        </a-row>
      </a-col>
      <a-col :xs="12" :sm="12" :md="8" :lg="4" :xl="4" :xxl="4">
        <a-row class="space_b_fifteen">
          <img
            src="@/assets/img/png/qr_code_official_accounts.png"
            alt="扫描二维码关注公众号"
            class="footer_img"
          />
          <div class="qr_code——text view_width-full">扫描二维码关注公众号</div>
        </a-row>
        <a-row class="space_b_fifteen">
          <img
            src="@/assets/img/png/qr_code_more.png"
            alt="扫描二维码了解更多"
            class="footer_img"
          />
          <div class="qr_code——text view_width-full">扫描二维码了解更多</div>
        </a-row>
      </a-col>
    </a-row>
    <a-row :gutter="30" type="flex" class="jooyum_logo_box">
      <a-col
        :xs="6"
        :sm="6"
        :md="8"
        :lg="10"
        :xl="10"
        :xxl="11"
        style="text-align: right"
      >
        <img src="@/assets/img/png/jooyum.png" alt="jooyum" />
      </a-col>
      <a-col
        :xs="18"
        :sm="18"
        :md="16"
        :lg="14"
        :xl="14"
        :xxl="13"
        class="jooyum_logo_text"
      >
        Copyright &copy;{{ currentYear }} by 上海正也信息科技有限公司
        <a href="https://beian.miit.gov.cn/" class="beian" target="_blank"
          >沪ICP备14016032号-3</a
        >
      </a-col>
    </a-row>
    <div
      class="footer_btn mouse-style"
      :style="{ opacity: this.$store.state.fixHeader.fixFooterBtn ? 1 : 0 }"
    >
      <div class="subscribe_big">
        <div
          @click="toPage('subscribe')"
          class="subscribe1"
          @mouseenter="subscribeEnter"
          @mouseleave="subscribeLeave"
        >
          <div class="sun_img_dev" v-show="subscribe1Show">
            <img src="~@/assets/img/svg/icon_demonstration_grey.svg" alt />
          </div>
          <div class="sun_img_dev" v-show="!subscribe1Show">
            <img src="~@/assets/img/svg/icon_demonstration_blue.svg" alt />
          </div>

          <div
            class="subscribe1_text"
            style="font-size: 14px;
    margin-left: 7px;
    margin-top: 14px;"
          >
            预约演示
          </div>
        </div>

        <div
          class="subscribe2"
          @mouseenter="subscribeEnter2"
          @mouseleave="subscribeLeave2"
        >
          <div class="sun_img_dev" v-show="subscribe2Show">
            <img src="~@/assets/img/svg/icon_customer_service_grey.svg" alt />
          </div>
          <div class="sun_img_dev" v-show="!subscribe2Show">
            <img src="~@/assets/img/svg/icon_customer_service_blue.svg" alt />
          </div>
          <div
            class="subscribe2_text"
            style="font-size: 14px;
            margin-left: 7px;
            margin-top: 14px;"
          >
            微信客服
          </div>

          <div class="vip-control-header">
            <div class="wechat_text">微信客服</div>
            <div class="wechat_img">
              <img src="~@/assets/img/png/qrcode.png" alt />
            </div>
            <div class="qucr_code">扫码咨询</div>
          </div>
        </div>
      </div>

      <div @click="backTop" class="backTop"></div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  beforeCreate() {
    this.currentYear = new Date().getFullYear();
  },
  data() {
    return {
      isTop: false,
      scrollTop: 0,
      subscribe1Show: true,
      subscribe2Show: true,
      footerList: [
        {
          title: "核心产品",
          id: 1,
          path_name: "salesManagement",
          child: [
            { title: "主数据平台", id: 1, path_name: "masterDataPlatform" },
            { title: "辖区指标系统", id: 2, path_name: "Jurisdiction" },
            { title: "销讯通•行为管理", id: 3, path_name: "p2ps" },
            { title: "彩蝶•流向管理", id: 4, path_name: "flowSteward" },
            { title: "芒哥•合规管理", id: 5, path_name: "mg" },
            { title: "虎珀•费用管理", id: 6, path_name: "hp" },
            { title: "财猫•订单管理", id: 7, path_name: "cm" },
            // { title: "向日葵", id: 6, path_name: "sunflower" },
          ],
        },
        {
          title: "解决方案",
          id: 2,
          path_name: "solutionMedicineManagement",
          child: [
            {
              title: "传统企业信息化",
              id: 1,
              path_name: "solutionInformatization",
            },
            {
              title: "符合全场景营销",
              id: 2,
              path_name: "solutionDirectSelling",
            },
            {
              title: "渠道与费用管控",
              id: 3,
              path_name: "solutionChannelOfDistribution",
            },
            {
              title: "支付证据管理",
              id: 4,
              path_name: "solutionBazaarAdministration",
            },
          ],
        },
        {
          title: "新闻资讯",
          id: 3,
          meta_type: "1",
          anchor_type: 0,
          path_name: "newsConsultingNews",
          child: [
            {
              title: "企业新闻",
              id: 1,
              meta_type: "1",
              anchor_type: 1,
              href: "#news-cpmpany",
              path_name: "newsConsultingNews",
            },
            {
              title: "行业新闻",
              id: 2,
              meta_type: "2",
              anchor_type: 1,
              href: "#news-business",
              path_name: "newsConsultingNews",
            },
          ],
        },
        {
          title: "关于我们",
          id: 4,
          anchor_type: 0,
          path_name: "aboutUs",
          child: [
            { title: "公司介绍", id: 1, path_name: "aboutUs", anchor_type: 1 },
            { title: "管理团队", id: 2, path_name: "aboutUs", anchor_type: 2 },
            { title: "资质荣誉", id: 3, path_name: "aboutUs", anchor_type: 3 },
          ],
        },
        {
          title: "联系我们",
          id: 5,
          path_name: "contactUs",
          child: [
            { title: "电话：400-118-0106", id: 1 },
            { title: "邮箱： hr@jooyum.com", id: 2 },
            {
              title: "地址：上海市长寿路652号景源时尚产业园10号楼202室",
              id: 3,
            },
          ],
        },
      ],
    };
  },
  created() {},

  methods: {
    subscribeEnter() {
      this.subscribe1Show = !this.subscribe1Show;
    },
    subscribeLeave() {
      this.subscribe1Show = !this.subscribe1Show;
    },
    subscribeEnter2() {
      this.subscribe2Show = !this.subscribe2Show;
    },
    subscribeLeave2() {
      this.subscribe2Show = !this.subscribe2Show;
    },
    toPage(name, itemData = {}) {
      let router = {
        name,
      };
      if (name == "newsConsultingNewsContent") {
        router.params = { type: 1 };
      } else if (["newsConsultingNews"].indexOf(name) > -1) {
        console.log("itemData", itemData);
        router.params = {
          meta_type: itemData.meta_type,
          anchor_type: itemData.anchor_type,
        };
      } else if (name == "aboutUs") {
        router.params = {
          anchor_type: itemData.anchor_type,
        };
      }
      this.$router.push(router);
    },
    backTop() {
      let timer = setInterval(() => {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/style/color.scss";
.footer {
  background-color: $color-lightWhite1;
  overflow-x: hidden;
  h2,
  h3 {
    margin-bottom: 0;
  }
  .row__title {
    color: $color-lightBlack;
    font-size: 0.2rem;
    padding-bottom: 0.2rem;
  }
  .row_col__item {
    padding-bottom: 0.15rem;
    color: $color-fixGray;
    word-break: break-all;
  }
}
.footer_row {
  padding: 40px 13.5%;
}
.company_copy {
  height: 58px;
  background-color: $color-lightGray;
}
.footer_btn {
  position: fixed;
  bottom: 0.8rem;
  right: 0.6rem;
  transition: all 0.6s ease;
  z-index: 100;
}
.subscribe {
  width: 64px;
  height: 64px;
}
.subscribe {
  background: url("~@/assets/img/png/subscribe.png");
  background-size: 100% 100%;
}
.subscribe_big {
  width: 70px;
  height: 140px;
  margin-bottom: 18px;
  border-radius: 6px;
  border: 1px solid #e3e7ec;
  background: #ffffff;
}
.subscribe1 {
  width: 70px;
  height: 70px;
  border-bottom: 1px solid #e3e7ec;
  .sun_img_dev {
    width: 26px;
    height: 26px;
    line-height: 45px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.subscribe1:hover {
  .subscribe1_text {
    color: #4f97e7;
  }
}
.subscribe2:hover {
  .subscribe2_text {
    color: #4f97e7;
  }
}
.subscribe2 {
  width: 70px;
  height: 80px;
  .sun_img_dev {
    width: 26px;
    height: 26px;
    line-height: 45px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.backTop {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.backTop {
  cursor: pointer;
  background: url("~@/assets/img/svg/icon_top.svg");
  background-size: 100% 100%;
}
.space_b_fifteen {
  padding-bottom: 15px;
}
.qr_code——text {
  padding-top: 10px;
  color: $color-gray;
  font-size: 13px;
}
.jooyum_logo_box {
  align-items: center;
  min-height: 58px;
  background-color: $color-lightGray;
}
.jooyum_logo_text {
  font-size: 12px;
  color: $color-gray;
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
  .beian {
    color: $color-gray;
  }
}
.hover-move:hover,
.hover-move:link {
  color: #1890ff;
}
@media all and (max-width: 1200px) {
  .row__title {
    font-size: 14px !important;
    padding-bottom: 8px !important;
  }
  .row_col__item {
    font-size: 12px !important;
    padding-bottom: 10px !important;
  }
  .footer_img {
    width: 90px;
  }
  // .subscribe,
  // .backTop {
  //   width: 55px;
  //   height: 55px;
  // }
  .subscribe_big {
    //   width: 55px;
    // height: 55px;
    width: 64px;
    height: 128px;
    .backTop {
      width: 55px;
      height: 55px;
    }
  }
}
@media all and (max-width: 768px) {
  .footer_img {
    width: 80px;
  }
  .subscribe,
  .backTop {
    width: 35px;
    height: 35px;
  }
}

.vip-control-header {
  width: 170px;
  height: 230px;
  background: #ffffff;
  box-shadow: #ddd 0px 0px 10px;
  padding: 10px 0 10px 10px;
  position: absolute;
  top: 0px;
  right: 85px;
  border-radius: 5px;
  z-index: -1;
  display: none;
  // opacity: 0;
}
.vip-control-header:before,
.vip-control-header:after {
  position: absolute;
  content: " ";
  top: 100px;
  // left: -16px;
  display: block;
  width: 0;
  height: 0;
  // border: 8px solid #F8F8F8;
  // border: 8px solid red;
  // border-color: transparent #F8F8F8 transparent transparent;
  //  border-color: transparent red transparent transparent;
  z-index: 111;
  border: 8px solid transparent;
  border-left: 8px solid #ffffff;
  right: -15px;
}
// .vip-control-header:after{
//     right: -17px;
//     border-color: transparent #e2e2e2 transparent transparent;
//     z-index: 11;
// }
.wechat_img {
  width: 150px;
  height: 150px;
  margin: 5px 0 5px 0;
  img {
    width: 100%;
    height: 100%;
  }
}
.wechat_text {
  width: 70px;
  margin: 0 auto;
}
.qucr_code {
  width: 70px;
  margin: 0 auto;
}
.subscribe2:hover .vip-control-header {
  // opacity: 1;
  display: block;
  // transition: all 0.4s ease 0s;
  transition: all 0.5s;
}
</style>
