<template>
  <header
    class="header"
    :class="{ shadow_fix: this.$store.state.fixHeader.changeFixed }"
  >
 


    <div class="header_menu view_width-full">
      <a-row type="flex" style="align-items: center">
        <a-col class="logo" :xs="12" :sm="12" :md="8" :lg="8" :xl="8" :xxl="8">
          <router-link to="/home"
            ><img
              :src="svgSrc"
              style="width: 100%; max-width: 250px"
              alt="s2p"
          /></router-link>
        </a-col>
        <a-col
          class="nav__menu"
          :xs="12"
          :sm="12"
          :md="16"
          :lg="16"
          :xl="16"
          :xxl="16"
        >
          <a-menu
            mode="horizontal"
            :selectable="false"
            @click="toSecondPage"
            :overflowedIndicator="overflowedIcon"
          >
            <template v-for="item in menuList">
              <a-menu-item v-if="!item.child" :key="item.key">
                <span
                  class="submenu-title-wrapper_temp fz_14"
                  :style="{
                    fontWeight: item.key == 'phone' ? 'bold' : 'lighter',
                  }"
                  >{{ item.title }}</span
                >
              </a-menu-item>
              <a-sub-menu
                :key="item.key"
                v-else
                @titleClick="is_moble ? '' : toPage(item)"
              >
                <span slot="title" class="submenu-title-wrapper fz-14">{{
                  item.title
                }}</span>
                <a-row
                  :gutter="20"
                  style="max-width: 900px; padding: 20px 20px 0 20px"
                  v-if="item.key == 'coreProduct'"
                  type="flex"
                >
                  <a-col
                    class="mouse-style"
                    style="padding-bottom: 20px"
                    :xs="12"
                    :sm="12"
                    :md="12"
                    :lg="8"
                    :xl="8"
                    :xxl="8"
                    v-for="item1 in item.child"
                    :key="item1.key"
                  >
                    <a-menu-item @click="toPage(item1)" :key="item1.key">
                      <div class="at_card_item">
                        <a-card class="bgc__move">
                          <a-row>
                            <a-col
                              :xs="24"
                              :sm="8"
                              :md="6"
                              :lg="8"
                              :xl="8"
                              :xxl="8"
                              class="text_left text-center"
                            >
                              <img
                                :src="item1.img_url"
                                :alt="item1.title"
                                style="width: 50px"
                            /></a-col>
                            <a-col
                              :xs="24"
                              :sm="16"
                              :md="18"
                              :lg="16"
                              :xl="16"
                              :xxl="16"
                              class="text_left text-center"
                            >
                              <h3 class="menu-item_title font-size_16">
                                {{ item1.title }}
                              </h3>
                              <p class="menu-item_desc" style="font-size: 12px">
                                {{ item1.desc }}
                              </p>
                            </a-col>
                          </a-row>
                        </a-card>
                      </div>
                    </a-menu-item>
                  </a-col>
                </a-row>
                <a-row
                  :gutter="20"
                  type="flex"
                  v-else
                  style="width: 9rem; padding: 20px"
                >
                  <a-col
                    v-for="item1 in item.child"
                    :key="item1.key"
                    :xs="24"
                    :sm="12"
                    :md="12"
                    :lg="12"
                    :xl="12"
                    :xxl="12"
                    class="menu_col_item bgc__move mouse-style"
                  >
                    <a-menu-item @click="toPage(item1)" :key="item1.key">
                      <div class="text_left">
                        <h2
                          :style="{
                            fontSize: '0.2rem',
                            color: '#333',
                            marginBottom: '8px',
                          }"
                        >
                          {{ item1.title }}
                        </h2>
                        <p
                          class="menu-item_desc menu-item-other_desc text_left font-size_16"
                          v-if="item1.desc"
                        >
                          {{ item1.desc }}
                        </p>
                      </div>
                    </a-menu-item>
                  </a-col>
                </a-row>
              </a-sub-menu>
            </template>
            <a-menu-item key="subscribe">
              <a-button type="primary" shape="round">预约演示</a-button>
            </a-menu-item>
          </a-menu>
        </a-col>
      </a-row>
    </div>
  </header>
</template>
<script>
import client from "@/assets/img/png/client.png";
import sunflow from "@/assets/img/png/sunflow.png";
import flow from "@/assets/img/png/flow.png";
import Jurisdiction from "@/assets/img/png/Jurisdiction.png";
import mg from "@/assets/img/png/mg.png";
import s2p from "@/assets/img/png/s2p.png";
import cm from "@/assets/img/png/cm.png";
import hp from "@/assets/img/png/hp.png";
export default {
  name: "HeaderMenu",
  beforeUpdate() {
    console.log("this.result", this.result);
  },
  data() {
    return {

      overflowedIcon: function () {
        return <a-icon type="unordered-list" />;
      },
       svgName:'logo.svg',
      logo:'@/assets/img/png/logo.svg?v=20210818',

      is_moble: false,
      menuList: [
        {
          title: "核心产品",
          key: "coreProduct",
          path_name: "salesManagement",
          child: [
            {
              title: "主数据平台",
              desc: "帮助企业沉淀分析主数据",
              key: "client",
              img_url: client,
              path_name: "masterDataPlatform",
            },
            {
              title: "辖区管理",
              desc: "销售辖区管理和指标下达计算",
              img_url: Jurisdiction,
              key: "Jurisdiction",
              path_name: "Jurisdiction",
            },
            {
              title: "销讯通•行为管理",
              desc: "绩效驱动与客户管理平台",
              path_name: "p2ps",
              img_url: s2p,
              key: "s2p",
            },
            {
              title: "彩蝶•流向管理",
              desc: "实现数据决策的价值",
              img_url: flow,
              key: "flow",
              path_name: "flowSteward",
            },
            {
              title: "芒哥•合规管理",
              desc: "专业服务商管理平台",
              img_url: mg,
              key: "mg",
              path_name: "mg",
            },
              {
                  title: "财猫•订单管理",
                  desc: "供应链数据管理解决方案",
                  img_url: cm,
                  key: "cm",
                  path_name: "cm",
              },{
              title: "虎珀•费用管理",
              desc: "费用结算管理平台",
              img_url: hp,
              key: "hp",
              path_name: "hp",
            },

            // {
            //   title: "向日葵",
            //   desc: "标签",
            //   img_url: sunflow,
            //   key: "sunflow",
            //   path_name: "sunflower",
            // },
          ],
        },
        {
          title: "解决方案",
          key: "solutionMedicineManagement",
          path_name: "solutionMedicineManagement",
          child: [
            {
              title: "传统企业信息化",
              desc: "制定企业数据标准，建立各业务系统数据",
              key: "solution1",
              path_name: "solutionInformatization",
            },
            {
              title: "符合全场景营销",
              desc: "集自营、代理、分销模式为一体的管理平台",
              key: "solution2",
              path_name: "solutionDirectSelling",
            },
            {
              title: "渠道与费用管控",
              desc: "对销售模式中的协议及合同、流向、返利进行有效管理",
              key: "solution3",
              path_name: "solutionChannelOfDistribution",
            },
            {
              title: "支付证据管理",
              desc:
                "服务于CSO/CSP模式下的，集合规、活动、培训、服务为一体的综合管理",
              key: "solution4",
              path_name: "solutionBazaarAdministration",
            },
          ],
        },
        {
          title: "新闻资讯",
          key: "newsInformation",
          meta_type: "1",
          anchor_type: 0,
          path_name: "newsConsultingNews",
          child: [
            {
              title: "企业新闻",
              key: "news1",
              meta_type: "1",
              anchor_type: 1,
              path_name: "newsConsultingNews",
            },
            {
              title: "行业新闻",
              key: "news2",
              meta_type: "2",
              anchor_type: 1,
              path_name: "newsConsultingNews",
            },
          ],
        },
        {
          title: "关于我们",
          path_name: "aboutUs",
          key: "aboutUs",
          anchor_type: 0,
          child: [
            {
              title: "公司介绍",
              key: "about1",
              anchor_type: 1,
              path_name: "aboutUs",
            },
            {
              title: "管理团队",
              key: "about2",
              anchor_type: 2,
              path_name: "aboutUs",
            },
            {
              title: "资质荣誉",
              key: "about3",
              anchor_type: 3,
              path_name: "aboutUs",
            },
          ],
        },
        { title: "联系我们", key: "contactUs", path_name: "contactUs" },
        { title: "Tel:400-118-0106", key: "phone" },
      ],
    };
  },
  mounted() {
    if (this.isMobile()) {
      this.is_moble = true;
    } else {
      this.is_moble = false;
    }
  },

  created() {
      this.svgName = this.$route.meta.logo+''
      console.log(this.svgName)
      if(this.svgName == 'undefined'){
          this.svgName='s2p.svg'
      }
  },
    computed: {
        svgSrc() {
            // 动态require SVG资源
            return require(`@/assets/logo/${this.svgName}`);
        },
    },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    toSecondPage(e) {
      if (e.key != "phone") {
        this.$router.push({
          name: e.key,
        });
      }
    },
    toPage(itemData) {
      let router = {
        name: itemData.path_name,
      };
      if (itemData.path_name == "newsConsultingNewsContent") {
        router.params = { type: 1 };
      } else if (["newsConsultingNews"].indexOf(itemData.path_name) > -1) {
        router.params = {
          meta_type: itemData.meta_type,
          anchor_type: itemData.anchor_type,
        };
      } else if (itemData.path_name == "aboutUs") {
        router.params = {
          anchor_type: itemData.anchor_type,
        };
      }
      this.$router.push(router);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/style/color.scss";
.header {
  background-color: $color-white;
  width: 100vw;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 80px;
  .header_menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /deep/ .ant-menu-horizontal {
      border-bottom: none;
    }
    /deep/ .ant-menu-submenu {
      // max-width: 50%;
      font-weight: lighter;
      color: $color-lightBlack;
      font-size: 0.18rem;
    }
    .submenu-title-wrapper_temp {
      font-weight: lighter;
      color: $color-lightBlack;
      font-size: 0.18rem;
    }
  }
}
.logo {
  text-align: center;
}
.shadow_fix {
  box-shadow: 0 5px 10px -6px $color-midleGray;
}
.menu-item_title {
  margin-bottom: 0;
}
.menu-item_desc {
  color: $color-gray;
  margin-bottom: 0;
  word-break: break-all;
  white-space: pre-line;
}
.menu-item-other_desc {
  word-break: break-all;
  white-space: pre-line;
}

.at_card_item {
  /deep/ .ant-card-body {
    padding: 0.24rem;
  }
}

.bgc__move:hover {
  background-color: $color-lightGray1;
  border-radius: 0.08rem;
}
.menu_col_item {
  padding: 0.24rem;
  line-height: 1.5;
}
@media all and (max-width: 1200px) {
  .header_menu {
    /deep/ .ant-menu-submenu {
      font-size: 14px !important;
    }
  }
}
@media all and (max-width: 768px) {
  .text-center {
    text-align: center;
  }
}
</style>
