import store from '@/store'
window.addEventListener('scroll', setStoreScrollData)
export function setStoreScrollData() {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
  if (scrollTop >= 100) {
    store.commit('setChangeFixed', true)
  } else {
    store.commit('setChangeFixed', false)
  }
  if (scrollTop >= 200) {
    store.commit('setFixFooterBtn', true)
  } else {
    store.commit('setFixFooterBtn', false)
  }
}
