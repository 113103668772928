import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import Antd from 'ant-design-vue'
import store from '@/store'
import axios from '@/api/axios'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import 'ant-design-vue/dist/antd.css'
Vue.prototype.$axios = axios
Vue.use(Antd)
Vue.component('Swiper', Swiper)
Vue.component('SwiperSlide', SwiperSlide)
Vue.component('directive', directive)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
