<template>
  <div>
    <header-menu ref="HeaderMenu"></header-menu>
    <div class="view-content">
      <div class="video__box">
        <video
          autoplay
          loop
          class="fillWidth"
          muted
          width="100%"
          style="
            width: 100%;
            object-fit: fill;
            min-height: 170px;
            height: '100%';
            -webkit-filter: grayscale(20%);
          "
        >
          <source src="@/assets/video/banner_home.mp4" type="video/mp4" />
          浏览器不支持 video 标签，建议升级浏览器。
        </video>
        <div class="fixed__box text_left">
          <h1 class="fixed__box-title fz_14">
            <div>基于SaaS的</div>
            <div>生命科学领域的营销</div>
            <div>智能管理解决方案</div>
          </h1>
          <p class="fixed__box-desc font-size_24">
            我们致力于将多年的医药大健康领域营销经验、互联网行业实践、以及忠于用户体验的精神，为传统生命科学的营销领域，提供专业的数字化智能营销及信息化平台建设方案。
          </p>
          <a-row type="flex" style="align-items: center" class="bottom_btn">
            <a-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" :xxl="4">
              <a-button type="primary" style="height: 100%" @click="toAboutUs">
                <div
                  class="about_us-btn font-size_20"
                  style="padding: 0.05rem 0"
                >
                  关于我们
                </div>
              </a-button>
            </a-col>
            <a-col
              span="14"
              class="mouse-style"
              @click="toPage('salesManagement')"
            >
              <a-row type="flex" style="align-items: center">
                <a-col>
                  <div class="about_us-btn font-size_20">了解更多</div>
                </a-col>
                <a-col>
                  <div class="abount_icon_right"></div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <div class="company_info font-size_20">
          <div class="left">
            <img
              src="~@/assets/img/png_new/gxjs.svg"
              alt="高新技术企业"
              style="min-width: 100px; width: 2.6rem"
            />
          </div>
          <div class="right">
            <img
              src="~@/assets/img/png_new/iso.svg"
              alt="ISO27001认证"
              style="min-width: 90px; width: 2.2rem"
            />
          </div>
        </div>
      </div>
      <div class="main">
        <div
          class="my__product t__space l_r_space"
          style="padding-bottom: 0.3rem"
        >
          <h2>
            <span
              class="nav__title mouse-style"
              @click="toPage('salesManagement')"
              >我们的产品</span
            >
            <div
              class="right-circle mouse-style"
              @click="toPage('salesManagement')"
            ></div>
          </h2>
          <p class="my__product-desc nav__desc">
            医药行业数字化闭环解决方案，支持处方推广、非处方推广、商务管理、招商推广、销售管理等多种业务场景
          </p>
          <div class="my__product-info">
            <a-row type="flex">
              <a-col
                class="mouse-style"
                :xs="24"
                :sm="12"
                :md="8"
                :lg="6"
                :xl="6"
                :xxl="6"

                v-for="(item, index) in productList"
                :key="index"
                style="padding-bottom: 0.6rem"
                @click="toPage(item.path_name)"
              >
                <img
                  :src="item.img_url"
                  :alt="item.title"
                  style="width: 20%; max-width: 80px"
                />
                <h3 class="info__title font-size_24">{{ item.title }}</h3>
                <p class="info__desc font-size_16">
                  {{ item.desc }}
                </p>
              </a-col>
            </a-row>
          </div>
        </div>
        <div class="solution t__space l_r_space">
          <h2>
            <span
              class="nav__title mouse-style"
              @click="toPage('solutionMedicineManagement')"
              >解决方案</span
            >
            <div
              class="right-circle mouse-style"
              @click="toPage('solutionMedicineManagement')"
            ></div>
          </h2>
          <p class="my__product-desc nav__desc">
            专业从事生命科学领域与医药大健康企业的信息化建设，专注于为行业提供完整的数字化营销和数据挖掘人才培养的解决方案
          </p>
          <div class="solution-info">
            <a-row :gutter="20" type="flex" style="flex-wrap: wrap">
              <a-col
                style="padding-bottom: 0.2rem"
                class="position__relative"
                :xs="24"
                :sm="12"
                :md="12"
                :lg="6"
                :xl="6"
                :xxl="6"
                v-for="(item, index) in solutionList"
                :key="index"
              >
                <a-card>
                  <div
                    @click="toPage(item.path_name)"
                    class="solution-info_item mouse-style"
                    @mouseover="mouseOver(index)"
                    @mouseleave="mouseLeave(index)"
                  >
                    <img
                      :src="item.img_url"
                      :alt="item.title"
                      style="width: 100%; height: 100%; max-width: 320px"
                    />
                    <div
                      class="solution-info__hover-animation"
                      :style="{ opacity: item.is_animation ? 0.8 : 0 }"
                    >
                      <div
                        class="solution-info__hover-animation solution-info__hover-animation_box"
                        :style="{
                          opacity: item.is_animation ? 0.8 : 0,
                          transform: item.is_animation
                            ? 'translateY(0%)'
                            : 'translateY(-20%)',
                        }"
                      >
                        <div
                          class="icon_box"
                          :id="'icon_box-hover_' + index"
                          @mouseover="mouseOverIcon(index)"
                          @mouseleave="mouseLeaveIcon(index)"
                        >
                          <a-icon type="double-right" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <h3 class="solution_title font-size_26">
                    {{ item.title }}
                  </h3>
                  <h4 class="solution_desc font-size_16" style="color: #666">
                    {{ item.desc }}
                  </h4>
                  <div class="detail_footer mouse-style font-size_20">
                    详情
                    <a-icon type="double-right" style="padding-left: 15px" />
                  </div>
                </a-card>
                <div
                  class="detail_footer_absolult mouse-style font-size_20"
                  @click="toPage(item.path_name)"
                >
                  详情
                  <a-icon type="double-right" style="padding-left: 15px" />
                </div>
              </a-col>
            </a-row>
          </div>
        </div>

        <div class="success_case" style="padding-bottom: 20px">
          <h2 class="nav__title" style="padding: 0.4rem 0">成功经典案例</h2>

          <!--<div class="evaluate__carouse_box" >-->
            <!--&lt;!&ndash;<ul class="wrap-boom">&ndash;&gt;-->
              <!--&lt;!&ndash;<li&ndash;&gt;-->
                <!--&lt;!&ndash;v-for="(item, index) in logoList1"&ndash;&gt;-->
                <!--&lt;!&ndash;:key="index + Math.random()"&ndash;&gt;-->
              <!--&lt;!&ndash;&gt;&ndash;&gt;-->
                <!--&lt;!&ndash;<img&ndash;&gt;-->
                  <!--&lt;!&ndash;:src="item.logo_url"&ndash;&gt;-->
                  <!--&lt;!&ndash;:alt="item.company_name"&ndash;&gt;-->
                  <!--&lt;!&ndash;class="view_width-full"&ndash;&gt;-->
                <!--&lt;!&ndash;/>&ndash;&gt;-->
              <!--&lt;!&ndash;</li>&ndash;&gt;-->
              <!--&lt;!&ndash;&lt;!&ndash;<li&ndash;&gt;&ndash;&gt;-->
                <!--&lt;!&ndash;&lt;!&ndash;v-for="(item, index) in logoList1"&ndash;&gt;&ndash;&gt;-->
                <!--&lt;!&ndash;&lt;!&ndash;:key="index + Math.random()"&ndash;&gt;&ndash;&gt;-->
              <!--&lt;!&ndash;&lt;!&ndash;&gt;&ndash;&gt;&ndash;&gt;-->
                <!--&lt;!&ndash;&lt;!&ndash;<img&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;:src="item.logo_url"&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;:alt="item.company_name"&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;class="view_width-full"&ndash;&gt;&ndash;&gt;-->
                <!--&lt;!&ndash;&lt;!&ndash;/>&ndash;&gt;&ndash;&gt;-->
              <!--&lt;!&ndash;&lt;!&ndash;</li>&ndash;&gt;&ndash;&gt;-->
            <!--&lt;!&ndash;</ul>&ndash;&gt;-->



              <!--<swiper ref="mySwiper" :options="swiperOptions">-->
                <!--<swiper-slide-->
                        <!--class="mouse-style"-->
                        <!--v-for="(a, index) in logoList2"-->
                        <!--:key="index"-->
                <!--&gt;-->
           <!---->
                <!--</swiper-slide>-->
              <!--</swiper>-->
              <!--<div class="swiper-button-next" slot="button-prev"></div>-->
              <!--<div class="swiper-button-prev" slot="button-next"></div>-->

          <!--</div>-->

          <div class="content-customer-wrap">
            <a-row :gutter="[16,16]">
              <a-col :span="3"   v-for="(item, index) in logoList1"
                     :key="item.logo_id">
                <a href="javaScript:;" @click="goLink(item.link)">
                <img
                        :src="item.logo_url"
                        :alt="item.company_name"
                        class="view_width-full"
                />
                </a>
              </a-col>
            </a-row>
          </div>
        </div>

        <div class="evaluate t__space l_r_space">
          <h2 class="nav__title">企业对我们的评价</h2>
          <div class="evaluate__carouse_box">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide
                class="mouse-style"
                v-for="(item, index) in evaluateList"
                :key="index"
              >
                <div style="padding-right: 0.2rem; box-sizing: border-box">
                  <div @click="clickActive(item)">
                    <img
                      :src="item.img_active"
                      :alt="item.name"
                      v-show="item.is_active"
                      class="img_box"
                    />
                    <img
                      :src="item.img_gray"
                      :alt="item.name"
                      v-show="!item.is_active"
                      class="img_box"
                    />
                  </div>
                  <div
                    :style="{
                      color: item.is_active ? '#003264' : '#999999',
                    }"
                  >
                    <div
                      :style="{
                        fontSize: '.2rem',
                        fontWeight: item.is_active ? 'bold' : 'normal',
                        padding: '.15rem 0 ',
                      }"
                    >
                      {{ item.name }}
                    </div>
                    <div
                      v-for="(item1, index1) in item.company"
                      :key="index1"
                      style="padding-bottom: 0.15rem"
                    >
                      <div
                        :style="{
                          fontSize: '.14rem',
                          fontWeight: item.is_active ? 'bold' : 'normal',
                        }"
                      >
                        {{ item1.name }}
                      </div>
                      <div
                        :style="{
                          fontSize: '.12rem',
                        }"
                      >
                        {{ item1.job }}
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-next" slot="button-prev"></div>
            <div class="swiper-button-prev" slot="button-next"></div>
            <!-- <carousel
              class="story-carousel story-carousel--colors story-carousel--multiple"
              @bound-left="boundLeftClick"
              @bound-right="boundRightClick"
            >
              <slide
                class="story-carousel__slide mouse-style"
                v-for="(item, index) in evaluateList"
                :key="index"
              >
                <div style="padding-right: 0.2rem; box-sizing: border-box">
                  <div @click="clickActive(item)">
                    <img
                      :src="item.img_active"
                      :alt="item.name"
                      v-show="item.is_active"
                      class="img_box"
                    />
                    <img
                      :src="item.img_gray"
                      :alt="item.name"
                      v-show="!item.is_active"
                      class="img_box"
                    />
                  </div>
                  <div
                    :style="{
                      color: item.is_active ? '#003264' : '#999999',
                    }"
                  >
                    <div
                      :style="{
                        fontSize: '.2rem',
                        fontWeight: item.is_active ? 'bold' : 'normal',
                        padding: '.15rem 0 ',
                      }"
                    >
                      {{ item.name }}
                    </div>
                    <div
                      v-for="(item1, index1) in item.company"
                      :key="index1"
                      style="padding-bottom: 0.15rem"
                    >
                      <div
                        :style="{
                          fontSize: '.14rem',
                          fontWeight: item.is_active ? 'bold' : 'normal',
                        }"
                      >
                        {{ item1.name }}
                      </div>
                      <div
                        :style="{
                          fontSize: '.12rem',
                        }"
                      >
                        {{ item1.job }}
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
              <template #arrows="{ changeSlide }">
                <div
                  style="left: -6%"
                  @click="changeSlide(-1)"
                  class="icon__left mouse-style"
                >
                  <a-icon
                    type="left"
                    style="color: #ccc"
                    class="icon__left_right font-size_26"
                  />
                </div>
                <div
                  style="right: -6%"
                  @click="changeSlide(1)"
                  class="icon__right mouse-style"
                >
                  <a-icon
                    type="right"
                    style="fcolor: #ccc"
                    class="icon__left_right font-size_26"
                  />
                </div>
              </template>
            </carousel> -->
          </div>
          <p class="evaluate__company bgc_f4fbff text_left font-size_20">
            {{ companyEvaluate }}
          </p>
        </div>
        <div class="news_information l_r_space">
          <h2>
            <span
              class="nav__title mouse-style"
              @click="toPage('newsConsultingNews')"
              >新闻资讯</span
            >
            <div
              class="right-circle mouse-style"
              @click="toPage('newsConsultingNews')"
            ></div>
          </h2>
          <div class="news_information-info">
            <a-tabs default-active-key="1" @change="changeNews">
              <a-tab-pane key="1" tab="企业新闻">
                <a-row
                  style="padding: 0.2rem 0 0.4rem 0; align-items: center"
                  type="flex"
                >
                  <a-col
                    :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="12"
                    :xl="12"
                    :xxl="12"
                    style="max-width: 600px"
                  >
                    <a-carousel
                      autoplay
                      dotsClass="custom_docts_class"
                      width="600"
                    >
                      <div
                        class="carousel__box"
                        v-for="(item2, index2) in carouselList"
                        @click="toPage('newsConsultingNewsContent', item2)"
                        :key="index2"
                      >
                        <img
                          :alt="item2.title"
                          :src="item2.picture_url"
                          class="view_width-height-full"
                        />
                        <div class="img_alt font-size_20">
                          {{ item2.title }}
                        </div>
                      </div>
                    </a-carousel>
                  </a-col>
                  <a-col
                    :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="12"
                    :xl="12"
                    :xxl="12"
                    style="padding-left: 5%"
                  >
                    <p
                      v-for="(item, index) in newsList"
                      :key="index"
                      class="mouse-style font-size_20"
                      style="color: #333"
                      @click="toPage(item.path_name, item)"
                    >
                      <a-row>
                        <a-col :span="18" class="text_left">
                          <a-row>
                            <a-col :span="1">
                              <div class="circle_gray"></div>
                            </a-col>
                            <a-col :span="23">
                              <div class="item_title">
                                {{ item.title }}
                              </div>
                            </a-col>
                          </a-row>
                        </a-col>
                        <a-col :span="6">{{ item.date }}</a-col>
                      </a-row>
                    </p>
                  </a-col>
                </a-row>
              </a-tab-pane>
              <a-tab-pane key="2" tab="行业新闻" force-render>
                <a-row
                  :gutter="40"
                  style="padding: 0.2rem 0 0.4rem 0; align-items: center"
                  type="flex"
                >
                  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
                    <a-carousel autoplay>
                      <div
                        class="carousel__box"
                        v-for="(item2, index2) in carouselList"
                        @click="toPage('newsConsultingNewsContent', item2)"
                        :key="index2"
                      >

                        <img
                          :alt="item2.title"
                          :src="item2.picture_url"
                          class="view_width-height-full"
                        />
                        <div class="img_alt font-size_20">
                          {{ item2.title }}
                        </div>
                      </div>
                    </a-carousel>
                  </a-col>
                  <a-col
                    :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="12"
                    :xl="12"
                    :xxl="12"
                    style="padding-left: 5%"
                  >
                    <p
                      class="font-size_20"
                      v-for="(item, index) in newsList"
                      :key="index"
                      style="color: #333"
                      @click="toPage(item.path_name, item)"
                    >
                      <a-row>
                        <a-col :span="18" class="text_left">
                          <a-row>
                            <a-col :span="1">
                              <div class="circle_gray"></div>
                            </a-col>
                            <a-col :span="23">
                              <div class="item_title">
                                {{ item.title }}
                              </div>
                            </a-col>
                          </a-row>
                        </a-col>
                        <a-col :span="6">{{ item.date }}</a-col>
                      </a-row>
                    </p>
                  </a-col>
                </a-row>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
        <div class="make__appointment t__space">
          <h2 class="nav__title">预约演示</h2>
          <h3 class="make__appointment__tip font-size_32">我们会及时联系您</h3>
          <h4 class="make__appointment__phone font-size_20">400-118-0106</h4>
          <div class="contactForm">
            <a-row>
              <a-col
                :xs="{ span: 12, offset: 6 }"
                :sm="{ span: 12, offset: 6 }"
                :md="{ span: 12, offset: 6 }"
                :lg="{ span: 12, offset: 6 }"
                :xl="{ span: 12, offset: 6 }"
                :xxl="{ span: 12, offset: 6 }"
              >
                <a-card class="card__box">
                  <a-form :form="form">
                    <a-row type="flex" style="align-items: center">
                      <a-col
                        :xs="{ span: 24 }"
                        :sm="{ span: 24 }"
                        :md="{ span: 12 }"
                        :lg="{ span: 12 }"
                        :xl="{ span: 12 }"
                        :xxl="{ span: 12 }"
                      >
                        <a-form-item style="text-align: center">
                          <div class="update_img_header">
                            <span style="font-size: 14px; padding-right: 15px"
                              >上传名片</span
                            >
                            <span style="font-size: 12px; color: #999999"
                              >图片为jpg或png格式,大小不超过2m</span
                            >
                          </div>
                          <a-upload
                            class="update_img"
                            name="file"
                            v-decorator="[
                              'fileList',
                              {
                                rules: [
                                  { required: false, message: '请上传名片111' },
                                ],
                              },
                            ]"
                            :action="
                              this.$axios.defaults.baseURL +
                              'cms/official/Subscribe/upload'
                            "
                            list-type="picture-card"
                            :file-list="fileList"
                            :beforeUpload="beforeUpload"
                            :remove="removeImg"
                            @preview="handlePreview"
                            @change="handleChange"
                            :withCredentials="true"
                            :accept="'image/*'"
                          >
                            <div
                              style="color: #b3cbf5"
                              v-if="fileList.length < 1"
                            >
                              <a-icon type="plus" style="font-size: 40px" />
                              <div class="ant-upload-text">点击上传图片</div>
                            </div>
                          </a-upload>

                          <span style="font-size: 12px; color: #999999"
                            >提示：可以通过上传名片自动识别企业信息</span
                          >
                        </a-form-item>
                      </a-col>
                      <a-col
                        :xs="{ span: 24 }"
                        :sm="{ span: 24 }"
                        :md="{ span: 12 }"
                        :lg="{ span: 12 }"
                        :xl="{ span: 12 }"
                        :xxl="{ span: 12 }"
                        style="text-align: left"
                      >
                        <a-form-item label="手机号">
                          <a-input
                            placeholder="请输入联系人手机号"
                            style="max-width: 640px"
                            type="number"
                            class="input_company"
                            v-decorator="[
                              'phone',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请输入联系人手机号',
                                  },
                                ],
                              },
                            ]"
                          />

                        </a-form-item>
                        <a-form-item label="企业名称">
                          <a-input
                            placeholder="请输入企业名称"
                            style="max-width: 640px"
                            class="input_company"
                            v-decorator="[
                              'company_name',
                              {
                                rules: [
                                  { required: false, message: '请输入企业名称' },
                                ],
                              },
                            ]"
                          />
                        </a-form-item>
                        <a-form-item label="姓名">
                          <a-input
                            placeholder="请输入联系人姓名"
                            style="max-width: 640px"
                            class="input_company"
                            v-decorator="[
                              'realname',
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: '请输入联系人姓名',
                                  },
                                ],
                              },
                            ]"
                          />
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-form>
                  <div style="text-align: center; padding-top: 0.6rem">
                    <a-button
                      shape="round"
                      type="primary"
                      style="width: 30%; height: 100%; min-width: 140px"
                      :size="'large'"
                      @click="_subMit"
                      :loading="btnLoading"
                    >
                      <div style="padding: 8px 10px">提交申请</div>
                    </a-button>
                  </div>
                </a-card>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <Footer></Footer>
  </div>
</template>
<script>
import { getList, subMit, getCarouselMap, newsList, newsLogoList } from "@/api";
import moment from "moment";
import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
import client from "@/assets/img/png/client.png";
import flow from "@/assets/img/png/flow.png";
import Jurisdiction from "@/assets/img/png/Jurisdiction.png";
import mg from "@/assets/img/png/mg.png";
import s2p from "@/assets/img/png/s2p.png";
import hp from "@/assets/img/png/hp.png";
import cm from "@/assets/img/png/cm.png";
import solution_3 from "@/assets/img/png/solution_3.png";
import solution_4 from "@/assets/img/png/solution_4.png";
import solution_2 from "@/assets/img/png/solution_2.png";
import solution from "@/assets/img/png/solution.png";
import evaluate_active_1 from "@/assets/img/png/evaluate_active_1.png";
import evaluate_gray_1 from "@/assets/img/png/evaluate_gray_1.png";
import evaluate_active_2 from "@/assets/img/png/evaluate_active_2.png";
import evaluate_gray_2 from "@/assets/img/png/evaluate_gray_2.png";
import evaluate_active_3 from "@/assets/img/png/evaluate_active_3.png";
import evaluate_gray_3 from "@/assets/img/png/evaluate_gray_3.png";
import evaluate_active_4 from "@/assets/img/png/evaluate_active_4.png";
import evaluate_gray_4 from "@/assets/img/png/evaluate_gray_4.png";
import evaluate_active_5 from "@/assets/img/png/evaluate_active_5.png";
import evaluate_gray_5 from "@/assets/img/png/evaluate_gray_5.png";
import evaluate_active_6 from "@/assets/img/png/evaluate_active_6.png";
import evaluate_gray_6 from "@/assets/img/png/evaluate_gray_6.png";
import evaluate_active_7 from "@/assets/img/png/evaluate_active_7.png";
import evaluate_gray_7 from "@/assets/img/png/evaluate_gray_7.png";
import evaluate_active_8 from "@/assets/img/png/evaluate_active_8.png";
import evaluate_gray_8 from "@/assets/img/png/evaluate_gray_8.png";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  name: "home",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  components: {
    Footer,
    HeaderMenu,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 6,
        spaceBetween: 20,
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
      },
      logoList1: [],
      logoList2: [],
      finished: false,
      previewVisible: false,
      previewImage: "",
      imgDisabled: false,
      fileList: [],
      newsTab: "1",
      btnLoading: false,
      provinceList: [],
      cityList: [],
      tabKey: "1",
      carouselList: [],
      evaluateList: [
        {
          name: "孙峰",
          key: "1",
          img_gray: evaluate_gray_1,
          img_active: evaluate_active_1,
          is_active: true,
          company: [
            {
              name: "一品红药业股份有限公司",
              job: "副总经理",
            },
            {
              name: "北大医药管理国际研究中心",
              job: "研究员",
            },
          ],
          companyEvaluate:
            "S2P是一款针对医药营销行为和结果关联管理的分析汇报软件。改变了合资企业基于代表行为管理的统计，升级为基于目标和任务执行的评估。只要简单的计划创建和执行评估，通过数据汇总看板极大的提高团队管理效率。真正实现企业从客户管理、目标管理、资源管理、任务管理到费用管理。是集传统销售管理业务的数据化和互联网化的最好体验。",
        },
        {
          name: "李滔",
          key: "2",
          img_gray: evaluate_gray_2,
          img_active: evaluate_active_2,
          is_active: false,
          company: [
            {
              name: "广东众生药业",
              job: "副总经理",
            },
          ],
          companyEvaluate:
            "销讯通平台，可以高效管理业务代表时间资源、活动资源，提高业务管理效率。同时，强化客户接触点管理，有效沉淀客户资源，提升客户终身价值。时间、活动、知识、客户，营销资源管理神器",
        },
        {
          name: "解庆峰",
          key: "3",
          img_gray: evaluate_gray_3,
          img_active: evaluate_active_3,
          is_active: false,
          company: [
            {
              name: "天津青松华药医药有限公司",
              job: "副总经理",
            },
            {
              name: "天津中新药业销售公司",
              job: "前总经理",
            },
          ],
          companyEvaluate:
            "S2P平台帮助企业-管队伍：虽不跟踪轨迹，但可确认真实过程。管工作：有计划，有审批，有结果，有评语。工作流程很清晰。管数据：分层，分块，分人，分段，有总有细，真正让数据讲道理。传信息：各种通知和学习按要求传递，还可作为培训的前沿阵地。",
        },
        {
          name: "黄慭",
          key: "4",
          img_gray: evaluate_gray_4,
          img_active: evaluate_active_4,
          is_active: false,
          company: [
            {
              name: "北京康辰药业营销中心",
              job: "总经理",
            },
          ],
          companyEvaluate:
            "一串数字“243650”，24小时工作值守，365天的查询与管理。碎片时间高效利用，一线销售者的伙伴和助手；0时差快速跟进，主管、经理协同工作桥梁；定期分析，公司管理的平台。",
        },
        {
          name: "薛志恒",
          key: "5",
          img_gray: evaluate_gray_5,
          img_active: evaluate_active_5,
          is_active: false,
          company: [
            {
              name: "清华大学医疗健康研究中心",
              job: "客座教授",
            },
          ],
          companyEvaluate:
            "以前的管理方式怎么效果大不如前？以前的管理工具怎么力不从心？以前的管理系统怎么开始超负荷运转？员工、客户、渠道、终端、系统、信息、资源等全要素生产率提升迫在眉睫，S2P销迅通平台是一个很好的效率提升工具！",
        },
        {
          name: "谭政",
          key: "6",
          img_gray: evaluate_gray_6,
          img_active: evaluate_active_6,
          is_active: false,
          company: [
            {
              name: "上海医药集团青岛国风药业股份有限公司",
              job: "总经理",
            },
          ],
          companyEvaluate:
            "销讯通医药营销管理系统，在同软件中更为专业，对管理细节做得也很优秀。整合并分析了零散数据片段，提取出终端和人效数据，对企业把控市场运作提供了一定的支持。搭建新型管理平台，对远程管理提供支持，也有效提升了任务下达到执行的效率。软件真实反映业务人员的工作岗位及内容，有效提升了业务人员的工作效率，将管理下放到各层级，帮助管理人员能力提升。及时反馈的终端数据和仪表盘功能，对管理者以及业务人员的工作方向有很大的支持作用。",
        },
        {
          name: "宋洪涛",
          key: "7",
          img_gray: evaluate_gray_7,
          img_active: evaluate_active_7,
          is_active: false,
          company: [
            {
              name: "石家庄以岭药业股份有限公司",
              job: "效率部经理",
            },
          ],
          companyEvaluate:
            "对代表行为管理我们一直持很谨慎的态度对待，如果没有一个好的系统我们宁可推迟这部分的细化管理，因为销售代表对自己的行为被量化管理大都是抵触的，倘若系统在设计和搭建上并不能人性化的搭配，以及没有一个友好的界面操作，那系统很难有推行下去。2018年我们在选择系统的时候经过多轮对比，最终觉得销讯通能满足我们的需求，并很好地解决了我们的担忧。后期我们很多差异化和定制化的要求也很好地得到实现，也是我愿意推荐销讯通的理由。",
        },
        {
          name: "夏超飞",
          key: "8",
          img_gray: evaluate_gray_8,
          img_active: evaluate_active_8,
          is_active: false,
          company: [
            {
              name: "济川药业集团有限公司",
              job: "信息管理中心总监",
            },
          ],
          companyEvaluate:
            "济川药业选择上海正也，搭建OTC零售领域的营销管理平台，利用S2P系统和APP端，并与ERP系统集成，实现了OTC营销的行为管理、数据管理、培训管理和流程打通；上海正也利用其对医药行业营销的深刻理解，结合互联网化的P2PS软件平台，给济川药业的OTC营销管理提供了优秀的解决方案，提供了优质及时的售后服务。",
        },
      ],
      companyEvaluate:
        "S2P是一款针对医药营销行为和结果关联管理的分析汇报软件。改变了合资企业基于代表行为管理的统计，升级为基于目标和任务执行的评估。只要简单的计划创建和执行评估，通过数据汇总看板极大的提高团队管理效率。真正实现企业从客户管理、目标管理、资源管理、任务管理到费用管理。是集传统销售管理业务的数据化和互联网化的最好体验。",
      // 新闻
      newsList: [],
      solutionList: [
        {
          title: "传统企业信息化",
          desc: "制定企业数据标准，建立各业务系统数据",
          img_url: solution,
          path_name: "solutionInformatization",
        },
        {
          title: "符合全场景营销",
          desc: "集自营、代理、分销模式为一体的管理平台",
          img_url: solution_2,
          path_name: "solutionDirectSelling",
        },
        {
          title: "渠道与费用管控",
          desc: "对销售模式中的协议及合同、流向、返利进行有效管理",
          img_url: solution_3,
          path_name: "solutionChannelOfDistribution",
        },
        {
          title: "支付证据管理",
          desc: "服务于CSO/CSP模式下的，集合规、活动、培训、服务为一体的综合管理",
          img_url: solution_4,
          path_name: "solutionBazaarAdministration",
        },
      ],
      //我们的产品
      productList: [
        {
          title: "主数据平台",
          desc: "帮助企业沉淀分析主数据，可提供主数据清洗服务及覆盖率分析",
          img_url: client,
          path_name: "masterDataPlatform",
        },
        {
          title: "辖区指标系统",
          desc: "销售辖区管理、销售指标下达及达成计算",
          img_url: Jurisdiction,
          path_name: "Jurisdiction",
        },
        {
          title: "销讯通•行为管理",
          desc: "销售行为管理、客户沟通过程管理、行为绩效分析",
          img_url: s2p,
          path_name: "p2ps",
        },
        {
          title: "彩蝶•流向管理",
          desc: "流向直连采集、流向自动归属、智能销售分析",
          img_url: flow,
          path_name: "flowSteward",
        },
        {
          title: "芒哥•合规管理",
          desc: "服务商客户与证据管理、合规报告采集与生成",
          img_url: mg,
          path_name: "mg",
        },
        {
          title: "虎珀•费用管理",
          desc: "全场景政策维护、一体化结算流程、多元化结算模式",
          img_url: hp,
          path_name: "hp",
        },
        {
          title: "财猫•订单管理",
          desc: "生产计划与库存管理、订单全流程管理、商业回款管理",
          img_url: cm,
          path_name: "cm",
        },
        // {
        //   title: "向日葵",
        //   desc: "结合主数据与流向管理，实施灵活自由的费用核算",
        //   img_url: sunflow,
        //   path_name: "sunflower",
        // },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  created() {
    this._getCarouselMap();
    this.getNewsList();
    this._getList();
    this.getLogoList();
  },
  methods: {
    moment,
    changeNews(e) {
      console.log("e", e);
      this.newsTab = e;
      this.getNewsList();

        this._getCarouselMap();
    },
    getLogoList() {
      newsLogoList().then((res) => {
        let data = res.data;
//        this.logoList1 = data.slice(0, start);
          this.logoList1 = data;
//          this.logoList1=[...data,...data,...data,...data,...data,...data,...data,...data,...data,...data,...data,...data,...data,...data,...data,...data,...data,...data];

//          let length =  this.logoList1.length;
//          let start = Math.round(length / 24);
//          this.logoList2 = Array.from({ length: start }, (_, i) => i);
//          console.log(start);
//        this.logoList2 = data.slice(end - 1, length);

          console.log(this.logoList1);

          console.log(this.logoList2);
      });
    },
    getNewsList() {
      let parameter = { news_type: this.newsTab };
      newsList(parameter).then((res) => {
        if (res.status === 0) {
          console.log("res", res);
          let data = res.data;
          for (let item of data) {
            item.date = `[${moment(item.create_date).format("YYYY-MM-DD")}]`;
            item.path_name = "newsConsultingNewsContent";
          }
          this.newsList = data;
        }
      });
    },
    _getCarouselMap() {
       let parameter = { news_type: this.newsTab };
      getCarouselMap(parameter).then((res) => {
        if (res.status === 0) {
          console.log("res", res);

          let data = res.data;
            for (let item of data) {
                item.date = `[${moment(item.create_date).format("YYYY-MM-DD")}]`;
                item.path_name = "newsConsultingNewsContent";
            }
          this.carouselList = data;
            console.log(this.carouselList )
        }
      });
    },
    selectProvince(e) {
      this.form.resetFields("city_name");
      this.cityList = this.provinceList.find(
        (item) => item.region_name == e
      ).child;
    },
    _getList() {
      let parameter = {};
      getList(parameter).then((res) => {
        if (res.status === 0) {
          let data = res.data || [];
          this.provinceList = data;
        }
      });
    },
    removeImg() {
      console.log("1111");
      this.fileList = [];
      this.form.resetFields("fileList");
    },
    handleCancel() {
      this.previewVisible = false;
    },
    beforeUpload(file, fileList) {
      console.log("file", file);
      console.log("fileList", fileList);
      let type = file.type;
      if (type !== "" && !/^image\//.test(type)) {
        this.$message.warning("请选择图片格式的文件");
        this.fileList = [];
        this.form.resetFields("fileList");
        this.finished = false;
        return false;
      } else {
        let _arr = file.name.split(".");
        console.log("_arr[_arr.length - 1]", _arr[_arr.length - 1]);
        if (["png", "jpg"].indexOf(_arr[_arr.length - 1]) == -1) {
          this.$message.warning("请选择jpg或png格式的图片");
          this.fileList = [];
          this.form.resetFields("fileList");
          this.finished = false;
          return false;
        } else {
          this.finished = true;
          return true;
        }
      }
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      if (
        fileList[0] &&
        fileList[0].status == "done" &&
        fileList[0].response.status
      ) {
        this.finished = false;
        this.fileList = [];
        this.form.resetFields("fileList");
        this.$message.error(fileList[0].response.msg);
        return;
      }
      if (this.finished) {
        this.fileList = fileList;
        console.log("fileList", fileList);
        if (fileList.length >= 1) {
          this.imgDisabled = true;
        } else {
          this.imgDisabled = false;
        }

        if (
          fileList.length &&
          fileList[0].status &&
          fileList[0].status == "done"
        ) {
          if (this.fileList[0].response.data.identifyResult) {
            let data = this.fileList[0].response.data.identifyResult.data;
            this.form.setFieldsValue({
              company_name: data.company_name,
              realname: data.realname,
              phone: parseInt(data.phone.replace(/\s*/g, "")),
            });
            this.$message.success(
              "自动识别成功，信息可能存在误差，请检查信息是否正确后再提交"
            );
          } else {
            this.$message.error(
              "自动识别失败，请自行填写企业信息或重新上传图片"
            );
          }
        }
      }
    },
      goLink(link){
        if(link){
            window.open(link);
        }
      },
    _subMit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.fileList) {
            delete values.fileList;
          }
//          if (!this.fileList.length) {
////            this.$message.error("请上传名片");
//            return;
//          }
            if (this.fileList.length) {
                values.img_url = this.fileList[0].response.data.file_url;
            }
          this.btnLoading = true;
          subMit(values).then((res) => {
            if (res.status === 0) {
              this.$success({
                title: "提示",
                content: res.msg,
              });
              this.form.resetFields();
              this.fileList = [];
              this.btnLoading = false;
            } else {
              this.$message.error(res.msg);
              this.btnLoading = false;
            }
          });
        }
      });
    },
    toPage(name, itemData = {}) {
      let router = {
        name,
      };
      console.log(name)
      if (name == "newsConsultingNewsContent") {
        router.params = { news_id: itemData.news_id };
      } else if (["newsConsultingNews"].indexOf(name) > -1) {
        if (itemData.news_type) {
          router.params = {
            meta_type: itemData.news_type,
            anchor_type: itemData.news_type ? 1 : 0,
          };
        } else {
          router.params = { meta_type: this.newsTab, anchor_type: 0 };
        }
      }
      this.$router.push(router);
    },
    boundLeftClick(e) {
      console.log("e", e);
    },
    clickActive(itemData) {
      if (!itemData.is_active) {
        for (let item of this.evaluateList) {
          if (item.key == itemData.key) {
            item.is_active = true;
            this.companyEvaluate = item.companyEvaluate;
          } else {
            item.is_active = false;
          }
        }
      }
    },
    pageClick() {},
    boundRightClick(e) {},
    toAboutUs() {
      this.$router.push({
        name: "aboutUs",
        params: { anchor_type: 0 },
      });
    },
    mouseOver(index) {
      this.$set(this.solutionList[index], "is_animation", true);
    },
    mouseLeave(index) {
      this.$set(this.solutionList[index], "is_animation", false);
    },
    mouseOverIcon(index) {
      document.getElementById(`icon_box-hover_${index}`).style.color = "#000";
      document.getElementById(`icon_box-hover_${index}`).style.backgroundColor =
        "#fff";
    },
    mouseLeaveIcon(index) {
      document.getElementById(`icon_box-hover_${index}`).style.color = "#fff";
      document.getElementById(`icon_box-hover_${index}`).style.backgroundColor =
        "rgba(88, 95, 105, 0.3)";
    },
    handleSubmit() {},
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/style/color.scss";
video::-webkit-media-controls {
  display: none !important;
}

// 隐藏video 音量按钮
video::-webkit-media-controls-mute-button {
  display: none !important;
}

// 隐藏video 当前按钮
video::-webkit-media-controls-current-time-display {
  display: none !important;
}

// 隐藏video 总时间
video::-webkit-media-controls-time-remaining-display {
  display: none !important;
}

// 隐藏video 全屏按钮
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}
.video__box {
  position: relative;
  overflow: hidden;
  //z-index: -9999;
  .fixed__box {
    position: absolute;
    top: 1.92rem;
    left: 13.5%;
    color: $color-white;
    width: 100%;
    .fixed__box-title {
      font-size: 0.4rem;
      color: $color-white;
    }

    .fixed__box-desc {
      width: 5.4rem;
      padding: 0.45rem 0 0.35rem 0;
    }
  }
  .company_info {
    min-width: 260px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    display: flex;
    align-items: center;
    .left {
      padding-right: 0.86rem;
    }
    .right {
      padding-top: 0.15rem;
    }
  }
}

.news_information {
  background-color: $color-white;
  padding-top: 0.28rem;
  text-align: center;
}

.news_information-info {
  padding-top: 0.3rem;

  /deep/ .ant-tabs-nav-scroll {
    color: $color-darkBlue;
    .ant-tabs-nav .ant-tabs-tab-active {
      color: $color-lightBlue;
    }
  }

  /deep/ .ant-carousel {
    .slick-dots {
      text-align: left !important;
      position: absolute;
      top: 0.2rem;
      left: 0.2rem;

      li {
        button {
          width: 0.2rem !important;
          height: 0.2rem !important;
          min-width: 12px !important;
          min-height: 12px !important;
          border-radius: 0.03rem !important;
          background-color: $color-lightBlack !important;
          opacity: 0.5 !important;
          color: $color-white;
          font-size: 0.16rem;
          line-height: 0.16rem;
          text-align: center;
        }
      }

      .slick-active {
        button {
          background-color: $color-lightBlue !important;
          opacity: 1 !important;
        }
      }
    }
  }
}

.circle_gray {
  width: 0.08rem;
  height: 0.08rem;
  min-width: 6px;
  min-height: 6px;
  border-radius: 50%;
  display: inline-block;
  background-color: $color-midleGray;
  vertical-align: middle;
}

.item_title {
  display: inline-block;
  padding-left: 10px;
  overflow: hidden;
  width: 98%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.my__product {
  text-align: center;
  background-color: $color-lightWhite;
}

.my__product-desc {
  padding: 0.14rem 0 0.6rem 0;
}

.info__title {
  padding: 0.1rem 0;
}

.info__desc {
  color: $color-gray;
  width: 80%;
  margin: 0 auto;
}

.solution {
  background-color: $color-white;
  .detail_footer {
    color: $color-lightBlue;
    opacity: 0;
  }
}

.solution_title {
  padding: 0.35rem 0 0.1rem 0;
}

.solution_desc {
  color: $color-gray;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 0.35rem;
}

.success_case {
  background-color: $color-lightWhite;
}
.success_case__carouse {
  padding: 0.4rem 6% 1rem 6%;
}
.content-customer-wrap {
  overflow: hidden;
  width: 50%;
  margin: 0.1rem auto;
  margin-bottom: 0.5rem;
  display: flex;
  align-content: center;
  justify-content: center;
}
.content-customer-wrap ul {
  display: flex;
  width: auto;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.content-customer-wrap ul li {
  width: 2rem;
  height: 2.1rem;
  padding-right: 0.2rem;
  list-style: none;
  flex: none;
}
@keyframes rowup {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  /*0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(-50%,0, 0);
    transform: translate3d(-50%,0, 0);
  }
  70% {
    -webkit-transform: translate3d(-70%,0, 0);
    transform: translate3d(-70%,0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100%,0, 0);
    transform: translate3d(-100%,0, 0);
  }*/
}
.wrap-boom {
  -webkit-animation: 26s rowup linear infinite normal;
  animation: 26s rowup linear infinite normal;
}

.evaluate__company {
  padding: 0.5rem;
  border-radius: 6px;
  color: $color-lightBlack;
}

.make__appointment__tip {
  color: $color-fixGray;
  padding-top: 0.1rem;
}

.make__appointment__phone {
  color: $color-lightBlue;
  padding: 0.1rem 0 0.2rem 0;
  font-weight: bold;
}

.make__appointment {
  background-color: $color-lightWhite;
  text-align: center;
}

.abount_icon_right {
  background: url("~@/assets/img/png/click_right.png");
  width: 0.28rem;
  height: 0.28rem;
  background-size: 100% 100%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

.right-circle {
  width: 0.28rem;
  height: 0.28rem;

  display: inline-block;
  margin-left: 0.2rem;
  vertical-align: middle;
  margin-bottom: 0.1rem;
}

.carousel_img {
  height: 3.6rem !important;
  width: 6rem !important;
  background-size: 100% 100%;
}
.view_width-height-full {
  width: 100%;
  height: 100%;
}
.carousel__box {
  position: relative;
  cursor: pointer;

  .img_alt {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-lightBlack;
    opacity: 0.5;
    padding: 0.05rem 0.2rem;
    box-sizing: border-box;
    color: $color-white;
  }
}

// .success_case__carouse {
//   /deep/ .ant-carousel {
//     .slick-dots {
//       bottom: -0.5rem;

//       li {
//         button {
//           width: 0.18rem !important;
//           height: 0.18rem !important;
//           background-color: $color-middlePlusGray !important;
//           opacity: 1 !important;
//           border: 0.04rem solid $color-lightWhite;
//           border-radius: 50% !important;
//         }
//       }

//       .slick-active {
//         button {
//           background-color: $color-white !important;
//           width: 0.18rem !important;
//           height: 0.18rem !important;
//           border-radius: 50% !important;
//           border: 0.01rem solid $color-lightBlue !important;
//         }
//       }
//     }
//   }
// }

.evaluate {
  padding-bottom: 0.8rem;
}

.evaluate__carouse_box {
  padding: 0.5rem 6%;
  box-sizing: border-box;
  position: relative;
}


.content-customer-wrap {
  overflow: hidden;
  width: 70%;
  margin: 0.1rem auto;
  margin-bottom: 0.5rem;
  display: flex;
  align-content: center;
  justify-content: center;
}
/deep/ .ant-select-selection {
  min-height: 0.4rem;
}

.solution-info_item:hover,
.solution-info_item::after,
.solution-info_item::before {
  transition: all 0.5s ease;
}

.solution-info_item {
  position: relative;

  .solution-info__hover-animation {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all 0.5s ease;
    background-color: rgba(88, 95, 105, 0.3) !important;
    box-shadow: 0 5px 10px -6px $color-midleGray;
  }

  .solution-info__hover-animation_box {
    height: 115%;

    .icon_box {
      width: 0.4rem;
      height: 0.4rem;
      background-color: rgba(88, 95, 105, 0.3);
      border: 1px solid $color-white;
      position: absolute;
      top: 42.5%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
    }
  }
}

.success_case__carouse_box {
  .success_case__carouse_box__item {
    // width: 12.5%;
    // padding: 0 0.05rem 0.2rem 0;
    // box-sizing: border-box;
    width: 2.7rem;
    height: 1.8rem;
    // padding-right: .2rem;
  }
}

.fixed__box {
  h1,
  p {
    margin: 0;
  }
}

h1,
h2,
h3,
h4 {
  margin-bottom: 0;
}

.story-carousel {
  position: relative;

  .icon__left {
    position: absolute;
    top: 22%;
    transform: translateY(-50%);
  }

  .icon__right {
    position: absolute;
    top: 22%;
    transform: translateY(-50%);
    z-index: 2000;
  }
}

.story-carousel__slide {
  flex-basis: 16.67%;
}
.img_box {
  width: 100%;
  max-width: 131px;
  border-radius: 50%;
}
.form__item_m_h {
  min-height: 0.4rem;
}
.detail_footer_absolult {
  color: $color-lightBlue;
  position: absolute;
  bottom: 0.6rem;
  left: 50%;
  transform: translateX(-50%);
}
@media all and (max-width: 1200px) {
  .right-circle {
    margin-bottom: -3px;
  }
  .story-carousel__slide {
    flex-basis: 25%;
    .img_box {
      width: 80% !important;
    }
  }
  /deep/ .ant-carousel {
    .slick-dots {
      li {
        button {
          font-size: 12px !important;
        }
      }
    }
  }
}
.card__box {
  border-radius: 8px;
  box-shadow: #ddd 0px 0px 10px;
  .card__title {
    text-align: center;
  }
  .update_img {
    margin: 0 10%;
  }
  .input_company,
  .input_company:focus {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #eee !important;
    border-radius: 0;
  }
}
.update_img_header {
  text-align: left;
  padding-bottom: 20px;
}
.update_img_header::before {
  /*content: "*";*/
  /*color: red;*/
}
/deep/ .ant-upload.ant-upload-select-picture-card {
  width: 80%;
  background-color: #f4f9fe;
  height: 200px;
}
/deep/ .ant-upload-list-picture-card-container {
  width: 80%;
  height: 200px;
}
/deep/ .ant-upload-list-item {
  width: 100% !important;
  height: 100% !important;
}
/deep/ .ant-form-item {
  margin-bottom: 0;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item-info::before {
  left: 0;
}
.swiper-button-next {
  position: absolute;
  top: 50%;
  right: -3%;
  background-size: 23px 44px;
  transform: translateY(-50%);
}

.swiper-button-prev {
  position: absolute;
  top: 50%;
  background-size: 23px 44px;
  left: -3%;
  transform: translateY(-50%);
}
@media all and (max-width: 768px) {
  .company_info {
    bottom: 10px !important;
  }
  .solution {
    .detail_footer_absolult {
      font-size: 12px;
      bottom: 20px;
    }
  }
  .story-carousel__slide {
    flex-basis: 34%;
    .img_box {
      width: 80% !important;
    }
  }

  // .success_case__carouse {
  //   .prevArrow_box {
  //     left: -10% !important;
  //     .prevArrow {
  //       font-size: 12px !important;
  //     }
  //   }

  //   .nextArrow_box {
  //     right: -10% !important;
  //     .nextArrow {
  //       font-size: 12px !important;
  //     }
  //   }
  // }

  .story-carousel {
    .icon__left {
      left: -10% !important;
      .icon__left_right {
        font-size: 12px !important;
      }
    }

    .icon__right {
      right: -10% !important;
      .icon__left_right {
        font-size: 12px !important;
      }
    }
  }
}
@media all and (max-width: 600px) {
  .bottom_btn {
    .abount_icon_right {
      margin-left: 10px;
      width: 12px;
      height: 12px;
    }
  }
  .fixed__box-desc {
    display: none !important;
  }
}
</style>
