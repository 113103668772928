<template>
  <a-config-provider :locale="local">
    <div id="app"><router-view :key="$route.fullPath" /></div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import "@/assets/js/fontSize";
import { setStoreScrollData } from "@/utils/swatch";
export default {
  data: () => ({
    local: zhCN,
  }),
  methods: {},
  mounted() {
    setStoreScrollData();
  },
  created() {
    this.$nextTick(() => {
      // // 禁用右键
      // document.oncontextmenu = new Function("event.returnValue=false");
      // // 禁用选择
      // document.onselectstart = new Function("event.returnValue=false");
    });
  },
};
</script>
<style lang="scss">
@import "@/assets/style/common.scss";
#app {
  height: 100%;
  color: #333333;
}
</style>
