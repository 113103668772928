import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
Vue.use(Router)

//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      redirect: {
        name: 'Home',
      },
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: { title: '引领生命科学领域智能营销',description:'正也科技S2P销讯通专注于提供医药行业营销智能管理解决方案，帮助企业建立主数据库，提供辖区指标管理、行为管理、远程拜访、智能流向管理、绩效奖金管理、证据连管理、学习平台、智能报表，是一个综合的医药营销赋能管理平台。' },

    },
    {
      path: '/core-product/sales-management',
      name: 'salesManagement',
      component: () => import(/* webpackChunkName: "salesManagement" */ '@/views/coreProduct/salesManagement.vue'),
      meta: { title: '核心产品-医药大健康领域营销管理SaaS系统',description:'S2P智能管理平台将基础的数据平台有效地结合移动终端开发，提供销售效能管理、分销渠道管理、流向数据管理、营销闭环管理及主数据管理等营销管理SaaS系统。',
      logo:'s2p.svg'},
    },
    {
      path: '/core-product/master-data-platform',
      name: 'masterDataPlatform',
      component: () =>
        import(/* webpackChunkName: "masterDataPlatform" */ '@/views/coreProduct/masterDataPlatform.vue'),
      meta: {
        title: '医药主数据平台',description:'正也S2P医药主数据管理平台帮助企业清洗沉淀客户主数据，依托强大的行业平台数据库，可清洗主数据标准名称及省市区地址等各类字段，提高企业客户真实性。'
        ,logo:'jooyum.svg'
      },
    },
    {
      path: '/core-product/jurisdiction',
      name: 'Jurisdiction',
      component: () => import(/* webpackChunkName: "Jurisdiction" */ '@/views/coreProduct/Jurisdiction.vue'),
      meta: { title: '辖区与指标管理系统',description:'正也科技S2P科学合理的组织架构及辖区指标管理系统。能适应各种营销模式的辖区管理及指标下达，根据市场规模合理布局各辖区人员配置，及时优化绩效指标。'
          ,logo:'jooyum.svg' },

    },
    {
      path: '/core-product/p2ps',
      name: 'p2ps',
      component: () => import(/* webpackChunkName: "p2ps" */ '@/views/coreProduct/p2ps.vue'),
      meta: { title: 'S2P销讯通·行为管理',description:'S2P销讯通营销行为管理系统是面向未来的、基于”代表备案制度”体系下的医药营销智能管理系统，为医药企业提供主数据、辖区、行为等管理服务，为医药营销团队提供便捷实用性工具。'
          ,logo:'xst.svg'},
    },
    {
      path: '/core-product/flow-steward',
      name: 'flowSteward',
      component: () => import(/* webpackChunkName: "flowSteward" */ '@/views/coreProduct/flowSteward.vue'),
      meta: { title: '彩蝶·流向管理——营销流向数据采集管理平台',description:'彩蝶·流向管理——提供精准的流向数据清洗、查询、统计和销售业绩核算服务。支持终端“进销存”和商业流通等“连续节点数据监控”，提供多维度分析与多维度预警。帮助企业建立主数据系统，优化营销管理的实时性，为企业管理层提供数字化高效决策与绩效管理。'
          ,logo:'ciidee.svg'},
    },
    {
      path: '/core-product/mg',
      name: 'mg',
      component: () => import(/* webpackChunkName: "mg" */ '@/views/coreProduct/mg.vue'),
      meta: { title: '芒哥 · 服务商合规证据管理平台',description:'芒哥·合规管理是基于”代表备案制度”体系下的合规证据链管理系统，集云端、移动端(微信小程序) 一体化的应用型工具。采集与汇总推广服务合规信息，为医药营销团队提供可视、真实的、数字化的合规证据链。'
          ,logo:'mongo.svg'},
    },
      {
          path: '/core-product/cm',
          name: 'cm',
          component: () => import(/* webpackChunkName: "mg" */ '@/views/coreProduct/caimao.vue'),
          meta: { title: '财猫 · 订单管理平台',description:'财猫 · 订单管理满足工业企业、商业公司、推广服务等不同企业在销售、采购、入库、发货等场景下的数字化管理需求，提供基础数据支撑以实现费用结算的便捷性和准确性。'
              ,logo:'caimao.svg'},
      },
      {
          path: '/core-product/hp',
          name: 'hp',
          component: () => import(/* webpackChunkName: "mg" */ '@/views/coreProduct/hupo.vue'),
          meta: { title: '虎珀 · 费用结算管理平台',description:'虎珀 · 费用管理适用于工业下游费用结算和各级推广服务商结算场景。智能算法和数据处理技术，显著提高财务和运营工作效率。它以工业发货、终端流向和销售订单为基础数据源，与S2P的其他产品模块深度集成，实现了业务财务一体化、可视化。'
              ,logo:'hupo.svg'},
      },
    {
      path: '/core-product/sunflower',
      name: 'sunflower',
      component: () => import(/* webpackChunkName: "sunflower" */ '@/views/coreProduct/sunflower.vue'),
      meta: { title: '向日葵•结算系统' },
    },
    {
      path: '/solution/medicine-management',
      name: 'solutionMedicineManagement',
      component: () =>
        import(/* webpackChunkName: "solutionMedicineManagement" */ '@/views/solution/medicineManagement.vue'),
      meta: { title: '医药营销管理解决方案',description:'专业从事生命科学领域与医药大健康企业的信息化建设，专注于为行业提供完整的数字化营销和数据挖掘人才培养的解决方案。' },
    },
    {
      path: '/solution/informatization',
      name: 'solutionInformatization',
      component: () => import(/* webpackChunkName: "solutionInformatization" */ '@/views/solution/informatization.vue'),
      meta: { title: '正也S2P推动数字化管理转型',description:'正也S2P通过“管理+咨询+SaaS系统”的联动模式，为客户提供从传统营销管理提升到“智能化管理“，包含企业主数据的建立、清洗优化、业务流程及知识管理优化、打造数据分析体系，通过信息化建设战略布局与规划，切实帮助客户实现转型升级与价值落地。' },
    },
    {
      path: '/solution/direct-selling',
      name: 'solutionDirectSelling',
      component: () => import(/* webpackChunkName: "solutionDirectSelling" */ '@/views/solution/directSelling.vue'),
      meta: { title: 'S2P全场景营销管理解决方案',description:'正也S2P适用多渠道全模式。包含OTC零售、处方临床、基层第三终端广阔市场、商务渠道 等多种团队模式，帮助企业以数据持续沉淀与应用为核心，建立“数据化管理平台”，赋能营销决策。 ' },
    },
    {
      path: '/solution/channel-of-distribution',
      name: 'solutionChannelOfDistribution',
      component: () =>
        import(/* webpackChunkName: "solutionChannelOfDistribution" */ '@/views/solution/channelOfDistribution.vue'),
      meta: { title: '正也S2P费用结算管理解决方案 ',description:'正也S2P智能管理平台针对招商代理营销模式下的管理痛点，采用协议及合同管理、辖区管理、进销存管理、费用管理、回款管理以及预警管理，对营销过程中的执行状态进行统计分析预警。以“（流向+单据）×算法”的策略，围绕流向、费用、回款、结算，对数据进行智能计算分析。 '
          ,logo:'caimao.svg'},
    },
    {
      path: '/solution/bazaar-administration',
      name: 'solutionBazaarAdministration',
      component: () =>
        import(/* webpackChunkName: "solutionBazaarAdministration" */ '@/views/solution/bazaarAdministration.vue'),
      meta: { title: '合规证据链管理解决方案',description:'正也S2P智能管理平台是面向未来的、基于“代表备案制度”体系下的合规证据链系统，集云端、移动端（微信小程序）一体化的应用型工具。为医药企业、营销服务商提供基础信息管理服务，可用于收集与汇总综合服务商、项目服务商的日常工作数据，为医药营销与服务，提供有效的合规证据链。 ' },
    },
    // 企业新闻
    {
      path: '/news-consulting/news/:meta_type/:anchor_type',
      name: 'newsConsultingNews',
      component: () => import(/* webpackChunkName: "newsConsultingNews" */ '@/views/newsConsulting/news.vue'),
      meta: { title: '新闻资讯' ,description:'正也科技S2P专注于提供医药行业营销智能管理解决方案，帮助企业建立主数据库，提供辖区指标管理、行为管理、远程拜访、智能流向管理、绩效奖金管理、证据连管理、学习平台、智能报表，是一个综合的医药营销赋能管理平台。'},
    },
    {
      path: '/news-consulting/news-content/:news_id',
      name: 'newsConsultingNewsContent',
      component: () =>
        import(/* webpackChunkName: "newsConsultingNewsContent" */ '@/views/newsConsulting/newsContent.vue'),
      meta: { title: '新闻', },
    },
    {
      path: '/about-us/:anchor_type',
      name: 'aboutUs',
      component: () => import(/* webpackChunkName: "aboutUs" */ '@/views/aboutUs/index.vue'),
      meta: { title: '正也科技公司介绍',description:'正也科技（上海正也信息科技有限公司）是医药企业营销的智能化和数智化转型服务商，是高新技术企业。' },
    },
    {
      path: '/contact-us',
      name: 'contactUs',
      component: () => import(/* webpackChunkName: "contactUs" */ '@/views/contactUs/index.vue'),
      meta: { title: '联系我们' },
    },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: () => import(/* webpackChunkName: "subscribe" */ '@/views/subscribe/index.vue'),
      meta: { title: '预约演示','description':'为了帮助你更好地了解和使用S2P综合平台系列产品，我们特为你提供了产品体验活动。你可以在此页面提交您的演示申请。申请成功后，将由我们专属服务人员为你安排预约服务。  除了表单申请，你也可以直接拨打售前热线，获取更快的服务响应。 400-118-0106' },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    console.log('to', to)
    if (['aboutUs', 'newsConsultingNews'].indexOf(to.name) == -1) {
      return {
        x: 0,
        y: 0,
      }
    }
  },
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
    // 如果路由对象中定义了title属性，则设置页面标题
    if (to.meta && to.meta.title) {
        document.title = '正也科技S2P官网-'+to.meta.title
        // document.meta.
    }

    if(to.meta.description){
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description)
        meta.content = to.meta.description;
        head[0].appendChild(meta)
    }

    if (to.path) {
      if (window._hmt) {
        window._hmt.push(['_trackPageview', '/#' + to.fullPath])
      }
    }
    
    next()
})
export default router
