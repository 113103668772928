// 导入封装好的网络请求类工具
import { get, post } from './axios'
// 封装各种接口请求
// export const 接口名 = () => Network.get('/路由',参数对象);

//官网获取省市区
export const getList = parameter => post('cms/official/Subscribe/regionList', parameter)

//官网提交预约信息
export const subMit = parameter => post('cms/official/Subscribe/add', parameter)

//官网获取新闻列表
export const newsList = parameter => post('cms/official/news/newsList', parameter)

export const newsPage = parameter => post('cms/official/news/newsPage', parameter)

//官网获取轮播图
export const getCarouselMap = parameter => post('cms/official/news/getCarouselMap', parameter)

//官网新闻详情
export const newsRow = parameter => post('cms/official/news/newsRow', parameter)

//官网企业logo
export const newsLogoList = parameter => post('cms/official/news/logoList', parameter)
