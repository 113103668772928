import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import fixHeader from './module/fixHeader'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  getters,
  actions: {},
  modules: {
    fixHeader,
  },
})
