const fixHeader = {
  state: {
    changeFixed: false,
    fixFooterBtn: false,
  },
  mutations: {
    setChangeFixed: (state, changeFixed) => {
      state.changeFixed = changeFixed
    },
    setFixFooterBtn: (state, fixFooterBtn) => {
      state.fixFooterBtn = fixFooterBtn
    },
  },
  actions: {},
}

export default fixHeader
